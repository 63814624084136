import React     from "react";
import PropTypes from 'prop-types'
import { GoogleMap, Marker,
         withGoogleMap, withScriptjs
} from "react-google-maps";


const GoogleMapContainer = withScriptjs(withGoogleMap((props) => {
  const mapCenter = { lat: 50.239753, lng: 4.244843 }
  return (
    <GoogleMap defaultZoom={ 11 } defaultCenter={ mapCenter } options={ { draggable: false, gestureHandling: 'greedy' } }>
      <Marker position={ mapCenter } />
    </GoogleMap>
  );
}))

GoogleMapContainer.defaultProps = {
  googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3&libraries=geometry,drawing,places&key=AIzaSyC6UDsrph6CcKVp7_pKFGeyvo6bAY9JWrc",
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `400px` }} />,
  mapElement: <div style={{ height: `100%` }} />
}

export default GoogleMapContainer
