import React  from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles.sass";

import Carousel1Image from "./images/carousel-1.jpg"
import Carousel2Image from "./images/carousel-2.jpg"
import Carousel3Image from "./images/carousel-3.jpg"
import Carousel4Image from "./images/carousel-4.jpg"
import Carousel5Image from "./images/carousel-5.jpg"
import Carousel6Image from "./images/carousel-6.jpg"

const HomeImagesCarousel = (props) => {

  const settings = {
    dots:           true,
    infinite:       true,
    speed:          500,
    slidesToShow:   1,
    slidesToScroll: 1,
    autoplay:       true,
    autoplaySpeed:  3000,
    arrows:         false
  };

  return (
    <div className="home-carousel-container">
      <Slider {...settings}>
        <div className="slide-container">
          <div className="slide-overlay"></div>
          <img src={ Carousel1Image } />
        </div>
        <div className="slide-container">
          <div className="slide-overlay"></div>
          <img src={ Carousel2Image } />
        </div>
        <div className="slide-container">
          <div className="slide-overlay"></div>
          <img src={ Carousel3Image } />
        </div>
        <div className="slide-container">
          <div className="slide-overlay"></div>
          <img src={ Carousel4Image } />
        </div>
        <div className="slide-container">
          <div className="slide-overlay"></div>
          <img src={ Carousel5Image } />
        </div>
        <div className="slide-container">
          <div className="slide-overlay"></div>
          <img src={ Carousel6Image } />
        </div>
      </Slider>
      <div className="home-carousel-text d-none d-sm-block">
        <h1>
          Chocolaterie Artisanale
          <br /> Bressant
        </h1>
      </div>
      <div className="home-carousel-text d-block d-sm-none">
        <h1>
          Chocolaterie Bressant
        </h1>
      </div>
    </div>
  );
}

export default HomeImagesCarousel
