import React  from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles.sass";

import FactoryCarousel0Image from "./images/factory-carousel-0.jpg"
import FactoryCarousel1Image from "./images/factory-carousel-1.jpg"
import FactoryCarousel2Image from "./images/factory-carousel-2.jpg"
import FactoryCarousel3Image from "./images/factory-carousel-3.jpg"
import FactoryCarousel4Image from "./images/factory-carousel-4.jpg"
import FactoryCarousel5Image from "./images/factory-carousel-5.jpg"


const HomeFactoryCarousel = (props) => {

  const settings = {
    dots:           false,
    infinite:       true,
    speed:          500,
    slidesToShow:   1,
    slidesToScroll: 1,
    autoplay:       false
  };

  return (
    <div className="home-factory-carousel-container">
      <Slider {...settings}>
        <div className="slide-container">
          <img src={ FactoryCarousel0Image } />
        </div>
        <div className="slide-container">
          <img src={ FactoryCarousel1Image } />
        </div>
        <div className="slide-container">
          <img src={ FactoryCarousel2Image } />
        </div>
        <div className="slide-container">
          <img src={ FactoryCarousel3Image } />
        </div>
        <div className="slide-container">
          <img src={ FactoryCarousel4Image } />
        </div>
        <div className="slide-container">
          <img src={ FactoryCarousel5Image } />
        </div>
      </Slider>
    </div>
  );
}

export default HomeFactoryCarousel
