import 'core-js/es6/map';
import 'core-js/es6/set';
//import 'babel-polyfill';
import 'bootstrap';

import loadScript from 'load-script';
import $          from 'jquery';

window.jQuery = $;
window.$ = $;

import React          from 'react';
import Rails          from 'rails-ujs';
import Turbolinks     from 'turbolinks';
import WebpackerReact from 'webpacker-react';

import HomeImagesCarousel       from './components/home_images_carousel';
import HomeTestimonialsCarousel from './components/home_testimonials_carousel';
import GoogleMapContainer       from './components/google_map_container';
import HomeFactoryCarousel      from './components/home_factory_carousel';

import "./styles/application.sass"

Rails.start();
Turbolinks.start();

WebpackerReact.setup({ HomeImagesCarousel })
WebpackerReact.setup({ HomeTestimonialsCarousel })
WebpackerReact.setup({ GoogleMapContainer })
WebpackerReact.setup({ HomeFactoryCarousel })

document.addEventListener('turbolinks:load', function () {
  if(process.env.RAILS_ENV == "production") {
    if (typeof ga === 'undefined') {
      loadScript('https://www.google-analytics.com/analytics.js', function () {
        window.ga = window.ga || function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date
        ga('create', 'UA-8477609-1', 'auto')
        ga('send', 'pageview')
      })
    } else {
      ga('send', 'pageview')
    }
  }
  if (typeof FB !== "undefined" && FB !== null) {
    FB.XFBML.parse();
  }
})

import './images/home/chocolate.jpg'
import './images/home/cake.jpg'
import './images/home/biscuits.jpg'
import './images/specialities/raspberry-balls.jpg'
import './images/specialities/gourmandines.jpg'
import './images/specialities/chouchous.jpg'
import './images/specialities/pralines-vertes.jpg'
import './images/specialities/choco.jpg'
import './images/specialities/chocolate.jpg'
import './images/specialities/biscuits.jpg'
import './images/specialities/cakes.jpg'
import './images/celebrations/valentine-s-day-1.jpg'
import './images/celebrations/valentine-s-day-2.jpg'
import './images/celebrations/valentine-s-day-3.jpg'
import './images/celebrations/valentine-s-day-4.jpg'
import './images/celebrations/easter-1.jpg'
import './images/celebrations/easter-2.jpg'
import './images/celebrations/easter-3.jpg'
import './images/celebrations/easter-4.jpg'
import './images/celebrations/mothers-day-1.jpg'
import './images/celebrations/mothers-day-2.jpg'
import './images/celebrations/mothers-day-3.jpg'
import './images/celebrations/mothers-day-4.jpg'
import './images/celebrations/fathers-day-1.jpg'
import './images/celebrations/fathers-day-2.jpg'
import './images/celebrations/autumn-1.jpg'
import './images/celebrations/autumn-2.jpg'
import './images/celebrations/autumn-3.jpg'
import './images/celebrations/autumn-4.jpg'
import './images/celebrations/saint-nicolas-1.jpg'
import './images/celebrations/saint-nicolas-2.jpg'
import './images/celebrations/saint-nicolas-3.jpg'
import './images/celebrations/christmas-1.jpg'
import './images/celebrations/christmas-2.jpg'
import './images/celebrations/christmas-3.jpg'
import './images/celebrations/new-year-1.jpg'

