import React  from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles.sass";

const HomeTestimonialsCarousel = (props) => {
  const settings = {
    dots:           false,
    infinite:       true,
    speed:          500,
    slidesToShow:   1,
    slidesToScroll: 1,
    autoplay:       true,
    autoplaySpeed:  3000,
    arrows:         false,
    fade: true
  };

  return (
    <div className="home-testimonials-carousel-container">
      <Slider {...settings}>
        <div className="slide-container">
          <div className="slide-content">
            <h1>« Un délice tant pour le palais que pour les yeux »</h1>
            <h2>Un client</h2>
          </div>
        </div>
        <div className="slide-container">
          <div className="slide-content">
            <h1>« Ces pralines ont un goût de paradis »</h1>
            <h2>Une cliente</h2>
          </div>
        </div>
        <div className="slide-container">
          <div className="slide-content">
            <h1>« Ce sont de loin les meilleures pralines que j'ai dégustées »</h1>
            <h2>Une cliente</h2>
          </div>
        </div>
        <div className="slide-container">
          <div className="slide-content">
            <h1>« Des truffes à tomber »</h1>
            <h2>Une cliente</h2>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default HomeTestimonialsCarousel
